import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SchedulerGuard } from './core/guards/scheduler.guard';
import { OperatorGuard } from './core/guards/operator.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnderMaintenanceComponent } from './pages/under-maintenance/under-maintenance.component';
import { ExtratoComponent } from './pages/extrato/extrato.component';
import { SchedulingProgressComponent } from './pages/scheduling-progress/scheduling-progress.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'account/login', pathMatch: 'full'
  },
  {
    path: 'scheduling',
    loadChildren: () => import('../app/scheduling/scheduling.module').then(m => m.SchedulingModule),
    canActivate: [SchedulerGuard]
  },
  {
    path: 'operator',
    loadChildren: () => import('../app/operator/operator.module').then(m => m.OperatorModule),
    canActivate: [OperatorGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('../app/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'schedule-extract/:id',
    component: ExtratoComponent
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent
  },
  {
    path: 'scheduling-progress',
    component: SchedulingProgressComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
