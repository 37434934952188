import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatInput } from '@angular/material/input';
import { DialogPromptConfig } from 'src/app/layout/configs/dialog-prompt.config';

@Component({
  selector: 'app-dialog-prompt',
  templateUrl: './dialog-prompt.component.html',
  styleUrls: ['./dialog-prompt.component.scss']
})
export class DialogPromptComponent implements OnInit {
  input: MatInput;
  config: DialogPromptConfig;
  requiredError: boolean;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DialogPromptComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    this.bottomSheetRef.afterOpened().subscribe(() => {
      this.input.focus();
    });
  }

  closeMe(input: string): void {
    if (this.config.required && !input) {
      this.requiredError = true;
      this.input.focus();
      return;
    }

    this.requiredError = false;
    this.bottomSheetRef.dismiss(input);
  }
}
