<ng-template #loading>
  <mat-progress-bar class="loading" mode="query"></mat-progress-bar>
</ng-template>

<aside fxLayout="column" fxFlexFill (swipeleft)="closeMenu()">
  <mat-toolbar class="mat-elevation-z2">
    <mat-toolbar-row class="clickable" fxLayout="row" [routerLink]="homeRoute">
      <img [alt]="appName" src="assets/nova-logo2.png" class="logo-d7-size" />
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="menu hide-scrollbars" fxFlex="nogrow noshrink">
      <mat-nav-list *ngIf="perfilUsuario==2">

        <a routerLink="/scheduling" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todas os agendamentos">
            <mat-icon>list</mat-icon>
          </i>
          Solicitação
        </a>
        <!--<a routerLink="/scheduling/report-panel" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os usuários">
            <mat-icon>analytics</mat-icon>
          </i>
          Painel
        </a>-->
        <a routerLink="/scheduling-progress" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver progresso agendamento">
            <mat-icon>analytics</mat-icon>
          </i>
          Progresso
        </a>
      </mat-nav-list>

      <mat-nav-list *ngIf="perfilUsuario==1">
        <a routerLink="/operator/list_all_scheduling" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todas as solicitações">
            <mat-icon>list</mat-icon>
          </i>
          Solicitação
        </a>
        <a routerLink="/operator/customer_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os usuários">
            <mat-icon>groups</mat-icon>
          </i>
          Cliente
        </a>
        <a routerLink="/operator/order_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>local_shipping</mat-icon>
          </i>
          Pedido
        </a>
        <a routerLink="/operator/product_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>inventory_2</mat-icon>
          </i>
          Produtos
        </a>
        <a routerLink="/operator/subsidiary_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>store_mall_directory</mat-icon>
          </i>
          Filial
        </a>
        <a routerLink="/operator/user_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>person</mat-icon>
          </i>
          Usuário
        </a>
        <a routerLink="/scheduling-progress" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" mat-list-item>
          <i mat-icon-button matTooltip="Ver progresso agendamento">
            <mat-icon>analytics</mat-icon>
          </i>
          Progresso
        </a>
      </mat-nav-list>
  </div>

  <mat-toolbar class="mat-elevation-z1-upside-down">
    <mat-toolbar-row fxLayout="row">
      <span class="clickable" [routerLink]="homeRoute">
          {{ appVersion }}
        <span class="version-suffix">{{ appVersionSuffix$ | async }}</span>
      </span>
      <span fxFlex></span>
      <a mat-icon-button aria-label="Fechar painel" (click)="closeMenu()">
        <mat-icon aria-hidden>chevron_left</mat-icon>
      </a>
    </mat-toolbar-row>
  </mat-toolbar>
</aside>
