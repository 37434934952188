<div fxLayout="row">
  <div fxFlex="98">
    <h2>Alteração de Carga - Pendente de Aprovação</h2>
  </div>
  <mat-icon
    class="icon-click"
    (click)="dialogRef.close()"
  >
    close
  </mat-icon>
</div>

<mat-progress-bar *ngIf="loadingScream" mode="indeterminate"></mat-progress-bar>

<div fxLayout="column" class="container-margin">
  <div *ngIf="loadingGrid">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="alert alert-info" *ngIf="datasource.data.length < 1">
    <p>Nenhum registro encontrado</p>
  </div>

  <table *ngIf="datasource.data.length" mat-table [dataSource]="datasource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> # </th>
      <td mat-cell *matCellDef="let element"> {{ element.idAgendamentoItens }} </td>
    </ng-container>

    <ng-container matColumnDef="idAgendamentoItem">
      <th mat-header-cell *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let element"> {{ element.nomeComercial }} </td>
    </ng-container>

    <ng-container matColumnDef="novoVolume">
      <th mat-header-cell *matHeaderCellDef> Novo Volume </th>
      <td mat-cell *matCellDef="let element"> {{ element.novoVolume }} </td>
    </ng-container>

    <ng-container matColumnDef="usuarioId">
      <th mat-header-cell *matHeaderCellDef> Usuário </th>
      <td mat-cell *matCellDef="let element"> {{ element.usuarioNome }} </td>
    </ng-container>

    <ng-container matColumnDef="dtCadastro">
      <th mat-header-cell *matHeaderCellDef> Dt Cadastro </th>
      <td mat-cell *matCellDef="let element"> {{ element.dataCadastro | date: 'dd/MM/YYYY' }} </td>
    </ng-container>

    <ng-container matColumnDef="statausReprogramacao">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{ getStatus(element.status) }} </td>
    </ng-container>

    <!--<ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef> Ação </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          disabled="{{ loadingReprog }}"
          class="btn-primary"
          (click)="approve(element.id)"
        >
          task
        </mat-icon>

      </td>
    </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="length"
    [pageSize]="pageSize"
    (page)="changePage($event)"></mat-paginator>

  <div fxLayout="row" style="margin-top: 30px;">
    <button
      color="primary"
      [disabled]="loadingReprog"
      mat-raised-button
      (click)="approve(data.agendamento)"
    >
      APROVAR
      <mat-icon>task</mat-icon>
    </button>
  </div>

</div>
