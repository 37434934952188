import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService extends BaseService {

  createRequest(body: any): Observable<any> {
    return this.basePost(`/account/change-password`, body);
  }
}
