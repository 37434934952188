import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from './layout/services/layout.service';
import { ConfigService } from './core/services/config.service';
import { D7_UNDER_MAINTANANCE } from './core/constants/routes.constants';

@Component({
  selector: 'app-root',
  template: '<app-root-container></app-root-container>',
})
export class AppComponent implements OnInit {
  title = 'LogisticaApp';

  constructor(
    // private menuService: MenuService,
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    // private keycloakService: KeycloakService
  ) { }

  ngOnInit(): void {
    this.configService.get('underMaintenance').subscribe(yesOrNo => {
      if (yesOrNo) {
        this.layoutService.off();
        this.router.navigate([D7_UNDER_MAINTANANCE]);
      }
    });

    /*this.keycloakService.isLoggedIn()
      .then(logging => {
          if (!logging) {
            this.keycloakService.logout();
          }
    });*/
    /*if (!this.eGestorService.tokenExists()) {
      this.authService.populate();
      this.menuService.loadMainMenu();
    }*/
  }

}

