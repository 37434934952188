import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DialogConfirmationConfig } from 'src/app/layout/configs/dialog-confirmation.config';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit {
  config: DialogConfirmationConfig;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DialogConfirmationComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.config = data;
  }

  closeMe(option: string): void {
    this.bottomSheetRef.dismiss(option);
  }

  ngOnInit(): void {
  }

}
