import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { D7Module } from './d7/d7.module';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnderMaintenanceComponent } from './pages/under-maintenance/under-maintenance.component';
import { ExtratoComponent } from './pages/extrato/extrato.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SchedulingProgressComponent } from './pages/scheduling-progress/scheduling-progress.component';
import { BalanceLoadApproveComponent } from './pages/balance-load/pages/balance-load-approve/balance-load-approve.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnderMaintenanceComponent,
    ExtratoComponent,
    SchedulingProgressComponent,
    BalanceLoadApproveComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    D7Module,
    AppRoutingModule,
    HammerModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  exports: [
    BalanceLoadApproveComponent
  ],
  providers: [
    Title,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
