<mat-card>
  <mat-card-header>
    <mat-card-title>
      <code>:(</code>
      Sistema em Manutenção!
    </mat-card-title>
    <mat-card-subtitle>Estamos trabalhando para voltarmos o mais breve possível</mat-card-subtitle>
    <!--<button mat-fab color="accent" routerLink="/">
      <mat-icon>home</mat-icon>
    </button>-->
  </mat-card-header>
  <img mat-card-image alt="Imagem do sistema em manutençaõ" src="/assets/under-maintenance.png" />
  <mat-card-content>
    <!--<p>
      Tente digitar outra URL na barra de endereço, ou acesse a <a style="cursor: pointer;" routerLink="/">página inicial</a> do sistema.
    </p>-->
  </mat-card-content>
</mat-card>
