import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootContainerComponent } from './components/root-container/root-container.component';
import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuSidenavComponent } from './components/menu-sidenav/menu-sidenav.component';
import { LayoutService } from './services/layout.service';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/material/material.module';
import { LAYOUT_CONFIG, LayoutConfig } from './configs/layout.config';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserProfileMenuComponent } from './components/user-profile-menu/user-profile-menu.component';
import { OnMobileDirective } from './directives/on-mobile.directive';
import { ContainerTransparentComponent } from './components/container-transparent/container-transparent.component';
import { ContainerComponent } from './components/container/container.component';
import { InsertionDirective } from './directives/insertion.directive';
import { DialogAlertComponent } from './components/dialog-alert/dialog-alert.component';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import { DialogPromptComponent } from './components/dialog-prompt/dialog-prompt.component';
import { MenuTopbarComponent } from './components/menu-topbar/menu-topbar.component';

const layoutConfigDefault: LayoutConfig = {
  withExternalBar: false,
  appName: '',
  appVersion: ''
};

@NgModule({
  declarations: [
    RootContainerComponent,
    LayoutComponent,
    FooterComponent,
    HeaderToolbarComponent,
    LoaderComponent,
    MenuSidenavComponent,
    UserProfileMenuComponent,
    OnMobileDirective,
    ContainerTransparentComponent,
    ContainerComponent,
    InsertionDirective,
    DialogAlertComponent,
    DialogConfirmationComponent,
    DialogPromptComponent,
    MenuTopbarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  exports: [
    RootContainerComponent,
    LayoutComponent,
    FooterComponent,
    HeaderToolbarComponent,
    LoaderComponent,
    MenuSidenavComponent,
    UserProfileMenuComponent,
    OnMobileDirective,
    ContainerComponent,
    ContainerTransparentComponent,
    InsertionDirective,
    DialogAlertComponent,
    DialogConfirmationComponent,
    DialogPromptComponent,
    MenuTopbarComponent,
  ],
  providers: [
    {provide: LayoutConfig, useValue: layoutConfigDefault},
  ]
})
export class LayoutModule {
  static forRoot(config: LayoutConfig): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        {provide: LAYOUT_CONFIG, useValue: config},
      ]
    };
  }
}

export {LayoutService};
