import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { MediaObserver } from '@angular/flex-layout';
import { delay, tap } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private mediaSubscription;
  menuSidenavMode: string;
  isLoading$: Observable<boolean>;
  menuSidenavOpen$: Observable<boolean>;
  animatedClass$: Observable<string>;
  layoutColorClass$: Observable<string>;
  enabled$: Observable<boolean>;
  routerOutlet: RouterOutlet;
  private routerEventSubscription;

  constructor(
    private mediaObserver: MediaObserver,
    private layoutService: LayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private jwtService: JwtService,
  ) {
    const finish = (() => {
      this.layoutService.loaded();
      this.colorizeAndAnimate(true);
    });
    this.routerEventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log('!NavigationStart');
        this.animatedClass$ = of('');
        this.layoutService.loading();
      }
      if (event instanceof NavigationEnd) {
        // console.log('NavigationEnd!');
        if (event.url === '/operator') {
          this.router.navigate(['/operator/list_all_scheduling']);
        }
        /*if (this.jwtService.tokenExpired()) {
          this.jwtService.destroyToken();
          this.router.navigate(['login']);
        }*/
        finish();
      }
    });
  }

  ngOnInit(): void {
    this.isLoading$ = this.layoutService.isLoading();
    this.menuSidenavOpen$ = this.layoutService.menuSidenavOpen();
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe(changes => this.calculateSidenavStatus());
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
    this.routerEventSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    this.enabled$ = this.layoutService.isEnabled().pipe(tap(enabled => {
      if (enabled) {
        this.calculateSidenavStatus();
      }
      this.colorizeAndAnimate(false);
    }));
  }

  menuSidebarSwipeRight(): void {
    this.layoutService.menuSidenavExpanded();
  }

  menuSidenavClosed(): void {
    this.layoutService.menuSidenavCollapsed();
  }


  colorizeAndAnimate(withDelay: boolean): void {
    let layoutAnimation;
    let layoutColor;
    try {
      layoutAnimation = this.routerOutlet.activatedRouteData.layoutAnimation;
    } catch (e) {
      layoutAnimation = false;
    }
    try {
      layoutColor = this.routerOutlet.activatedRouteData.layoutColor;
    } catch (e) {
      layoutColor = 'none';
    }
    if (withDelay) {
      this.animatedClass$ = of(layoutAnimation ? 'animated' : '').pipe(delay(1));
      this.layoutColorClass$ = of('with-layout-color-' + layoutColor).pipe(delay(1));
    } else {
      this.animatedClass$ = of(layoutAnimation ? 'animated' : '');
      this.layoutColorClass$ = of('with-layout-color-' + layoutColor);
    }
  }

  calculateSidenavStatus(): void {
    const isMobile = this.mediaObserver.isActive('lt-md');
    this.menuSidenavMode = isMobile ? 'over' : 'side';
    if (isMobile) {
      this.layoutService.menuSidenavCollapsed();
    }
    /*else {
      this.layoutService.menuSidenavExpanded();
    }*/
  }

}
