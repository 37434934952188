import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-transparent',
  templateUrl: './container-transparent.component.html',
  styleUrls: ['./container-transparent.component.scss']
})
export class ContainerTransparentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
