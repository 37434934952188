import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {CONFIG_ENV_JSON_PATH} from 'src/app/core/constants/config.constants';

@Injectable()
export class ConfigService {
  appKey: string;
  private cache;
  constructor(private httpClient: HttpClient) {
    this.cache = new Map();
  }
  /**
   * Registra nas configurações para fins de cache
   * qual aplicação em questão
   * @param name Nome da aplicação
   * @param version Versão da aplicação
   */
  registerApp(name: string, version: string): void {
    this.appKey = `app:${name}|version:${version}`;
    // this.cache.set(this.appKey, {});
  }
  /**
   * Retorna um `Observable`. Se assinado vai
   * trazer todas as configurações em
   * um objeto só
   */
  getAll(): Observable<any> {
    return this.httpClient.get(CONFIG_ENV_JSON_PATH).pipe(
      take(1),
      tap(config => this.cache.set(this.appKey, config))
    );
  }
  /**
   * Retorna um `Observable`. Se assinado vai
   * trazer a configuração selecionada
   * @param property Propriedade da configuração a ser retornada no `Observable`
   */
  get(property: string): Observable<any> {
    return this.getAll().pipe(map(config => config[property])).pipe(take(1));
  }
  /**
   * Retorna um `Observable`. Se assinado vai
   * trazer as configurações selecionadas
   * em uma lista
   * @param properties Lista de propriedades da configuração a serem retornadas no `Observable`
   */
  getList(properties: string[]): Observable<any[]> {
    return forkJoin(properties.map(property => this.get(property))).pipe(take(1));
  }
}
