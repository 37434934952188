import { BehaviorSubject, Observable } from 'rxjs';

export abstract class BaseLoadService {
  private loadRecipient = new BehaviorSubject<boolean>(false);

  listener(): Observable<boolean> {
    return this.loadRecipient.asObservable();
  }

  emitir(value: boolean): void {
    this.loadRecipient.next(value);
  }
}
