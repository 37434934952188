import { Component, OnInit, ViewChild } from '@angular/core';
import { SchedulingProgressService } from './scheduling-progress.service';
import { ErrorModel } from 'src/app/core/models/error.model';
import { DialogService } from 'src/app/layout/services/dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-scheduling-progress',
  templateUrl: './scheduling-progress.component.html',
  styleUrls: ['./scheduling-progress.component.scss']
})
export class SchedulingProgressComponent implements OnInit {
  loading = false;
  datasource = new MatTableDataSource<any>();

  displayedColumns: string[] = [
    'id', 'dataCriacao', 'dataRetirada', 'caminhao', 'caminhaoPlaca', 'motorista', 'dtAprovado',
    'dtChegadaCaminhao', 'dtAguardando', 'dtCarregando', 'dtNf', 'dtCte', 'dtLiberado'
  ];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  private firstPage = 0;
  profile: any;

  constructor(
    private dialogService: DialogService,
    private schedulingProgressService: SchedulingProgressService,
    private jwtService: JwtService,
  ) { }

  ngOnInit(): void {
    if (this.jwtService.isToken()) {
      const jwtDecoded = this.jwtService.getDecodedToken();
      this.profile = jwtDecoded.userProfile;
    }

    this.getProgress(this.firstPage, this.pageSize);
  }

  getProgress(page: any, size: any): void {
    this.loading = true;
    this.schedulingProgressService.get(page, size).subscribe(results => {
      // console.log(results);
      this.datasource.data = results.content;
      this.length = results.totalElements;
    }, (error: ErrorModel) => {
      this.dialogService.showAlert(error.message);
    }).add(() => {
      this.loading = false;
    });
  }

  changePage(e: PageEvent): void {
    this.getProgress(e.pageIndex, e.pageSize);
  }

  search(): void {
    this.getProgress(this.firstPage, this.pageSize);
  }

  isAdminOrOperator(): boolean {
    return [1, 3].includes(this.profile);
  }

  isClient(): boolean {
    return [2].includes(this.profile);
  }

}
