import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  menuSidenavOpen$: Observable<boolean>;
  // title$: Observable<string>;

  constructor(private layoutService: LayoutService) { }

  openMenu(): void {
    this.layoutService.menuSidenavExpanded();
  }

  ngOnInit(): void {
    this.menuSidenavOpen$ = this.layoutService.menuSidenavOpen();
    // this.title$ = this.layoutService.getTitle();
  }
}
