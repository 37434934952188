import { Injectable } from '@angular/core';
import { BaseLoadService } from 'src/app/scheduling/components/wizard/services/base-load.service';

@Injectable({
  providedIn: 'root'
})
export class LoadReprogracaoCargaService extends BaseLoadService {

  constructor() {
    super();
  }
}
