import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from 'src/app/layout/services/layout.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Directive({
  selector: '[appOnMobile]'
})
export class OnMobileDirective implements OnInit, OnDestroy {
  @Input() appOnMobile: ('show' | 'hide');
  private breakpointSubscription;
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService) {
  }
  /**
   *
   */
  ngOnInit(): void {
    this.showOrHide(this.breakpointObserver.isMatched(Breakpoints.Handset));
    this.breakpointSubscription = this.layoutService.onMobile().subscribe(onMobile => this.showOrHide(onMobile));
  }
  /**
   *
   */
  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }
  /**
   *
   */
  private showOrHide(onMobile: boolean): void {
    if (!this.appOnMobile) {
      return;
    }
    const isShow = 'show' === this.appOnMobile;
    const isHide = 'hide' === this.appOnMobile;
    const block = ((onMobile && isShow) || (!onMobile && isHide));
    const none = ((onMobile && isHide) || (!onMobile && isShow));
    const display = block ? 'block' : none ? 'none' : null;
    if (display) {
      this.elementRef.nativeElement.style.display = display;
    }
  }
}
