import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageModel } from 'src/app/core/models/page.model';
import { BaseService } from 'src/app/core/services/base.service';
import { SchedulingListModel } from 'src/app/scheduling/models/scheduling-list.model';
import { RecipientModel } from 'src/app/scheduling/components/wizard/components/recipient/models/recipient.model';
import { SchedulingModel } from 'src/app/scheduling/models/scheduling.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService extends BaseService {
  private endpointPath = '/schedule';

  create(body: any): Observable<RecipientModel> {
    return this.basePost(`${this.endpointPath}`, body);
  }

  update(body: any, agendamentoId: any): Observable<RecipientModel> {
    return this.basePut(`${this.endpointPath}/${agendamentoId}`, body);
  }

  dropRascunhoById(agendamentoId: any): Observable<void> {
    return this.baseDelete(`${this.endpointPath}/delete/${agendamentoId}`);
  }

  getAll(page: any, size: any, cliente?: any,
         filial?: any, status?: any, placa?: any, ordem?: any, active?: any, direction?: any): Observable<PageModel<SchedulingListModel>> {
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('cliente', cliente || '')
      .append('filial', filial || '')
      .append('status', status || '')
      .append('placa', placa || '')
      .append('ordem', ordem || '')
      .append('active', active || '')
      .append('direction', direction || '')
    ;
    return this.baseGetWithParams(`${this.endpointPath}`, params);
  }

  getOne(id: any): Observable<SchedulingModel> {
    return this.baseGet(`${this.endpointPath}/${id}`);
  }

  getTotalizadores(): Observable<any> {
    return this.baseGet(`${this.endpointPath}/getTotalizadores`);
  }

  saveTruckEntry(body: any): Observable<any> {
    return this.basePost(`${this.endpointPath}/registry/truck-entry`, body);
  }
}
