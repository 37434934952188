import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorModel } from 'src/app/core/models/error.model';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(this.hadleHttpError.bind(this)));
  }

  private hadleHttpError(response): Observable<never> {
    try {
      console.warn('[ErrorInterceptor::handleHttpError] message: "%s", status: %d', response.message, response.status);
      const self = this;

      const okButHasSomeError = ((error) => {
        console.error('[ErrorInterceptor::okButHasSomeError] Algo errado na hora de montar o retorno.', error);
        console.warn('[ErrorInterceptor::okButHasSomeError] Verifique o tipo do retorno se bate com o que vem do backend.');
        return 'Erro ao montar o retorno.';
      });

      const badRequest = ((error) => {
        console.error('[ErrorInterceptor::badRequest] Erro negocial da API!', error);

        if (error instanceof Object) {
          if (error.error === 'invalid_grant') {
            return 'Credenciais inválidas.';
          }
          return (error.mensagem) ? error.mensagem : 'Erro de API: desconhecido';
        }
      });

      const unauthorized = ((error) => {
        console.error('[ErrorInterceptor::accessDennied] Sem autorização, sai fora do sistema!', error);
        return 'Sem autorização!';
      });

      const forbidden = ((error) => {
        console.error('[ErrorInterceptor::forbidden] Proibido!', error);
        return 'Você não tem permissão.';
      });

      const notFound = ((error) => {
        console.error('[ErrorInterceptor::notFound] Erro de não encontrado!', error);
        return 'Nenhum resultado encontrado.';
      });

      const methodNotAllowed = ((error) => {
        console.error('[ErrorInterceptor::methodNotAllowed] Método HTTP não existe!', error);
        console.warn('[ErrorInterceptor::methodNotAllowed] Verifique o método da requisição bate com o que está registrado no backend.');
        return 'Problema com a requisição.';
      });

      const internalServerError = ((error) => {
        console.error('[ErrorInterceptor::internalServerError] Erro interno no servidor!', error);
        // TODO tratamento temporário dessa mensagens que vem encapsuladas dentro de outra...

        const workaround = self.workaroundOnMessage((error.message || '') + '');
        return workaround.message || 'Erro interno no servidor!';
      });

      const requestFail = ((error) => {
        console.error('[ErrorInterceptor::serviceNotAvailable] Falhou o request!', error);
        return 'Serviço indisponível, tente novamente em alguns minutos.';
      });

      const statusPreprocessor = ((originalStatus, originalError) => {
        // TODO tratamento temporário dessa mensagens que vem encapsuladas dentro de outra...
        if (!originalError) {
          return originalStatus;
        }

        const workaround = self.workaroundOnMessage((originalError.message || '') + '');
        return workaround.status || originalStatus;
      });

      const errorMapping = {
        // 0×× Non state
        0: requestFail,
        // 1×× Informational
        // 100 Continue
        // 101 Switching Protocols
        // 102 Processing
        // 2×× Success
        200: okButHasSomeError,
        // 200 OK
        // 201 Created
        // 202 Accepted
        // 203 Non-authoritative Information
        // 204 No Content
        // 205 Reset Content
        // 206 Partial Content
        // 207 Multi-Status
        // 208 Already Reported
        // 226 IM Used
        // 3×× Redirection
        // 300 Multiple Choices
        // 301 Moved Permanently
        // 302 Found
        // 303 See Other
        // 304 Not Modified
        // 305 Use Proxy
        // 307 Temporary Redirect
        // 308 Permanent Redirect
        // 4×× Client Error
        400: badRequest,
        // 400 Bad Request
        401: unauthorized,
        // 401 Unauthorized
        // 402 Payment Required
        403: forbidden,
        // 403 Forbidden
        404: notFound,
        // 404 Not Found
        405: methodNotAllowed,
        // 405 Method Not Allowed
        // 406 Not Acceptable
        // 407 Proxy Authentication Required
        // 408 Request Timeout
        // 409 Conflict
        // 410 Gone
        // 411 Length Required
        // 412 Precondition Failed
        // 413 Payload Too Large
        // 414 Request-URI Too Long
        // 415 Unsupported Media Type
        // 416 Requested Range Not Satisfiable
        // 417 Expectation Failed
        // 418 I'm a teapot
        // 421 Misdirected Request
        // 422 Unprocessable Entity
        // 423 Locked
        // 424 Failed Dependency
        // 426 Upgrade Required
        // 428 Precondition Required
        // 429 Too Many Requests
        // 431 Request Header Fields Too Large
        // 444 Connection Closed Without Response
        // 451 Unavailable For Legal Reasons
        // 499 Client Closed Request
        // 5×× Server Error
        500: internalServerError,
      };
      if (response.status in errorMapping) {
        const errorModel = new ErrorModel(
          errorMapping[response.status](response.error),
          statusPreprocessor(response.status, response.error),
          response
        );
        this.snackBar.open(
        `${errorModel.message} `,
        `HTTP - ${errorModel.code}`,
        {duration: 2500, direction: 'ltr', verticalPosition: 'top'}
        );
        return throwError(errorModel);
      }
    } catch (e) {
      console.error(e, response);
    }
    return throwError(new ErrorModel(':( Ops! Algo inesperado aconteceu.', 0, response));
  }

  private workaroundOnMessage(message: string): any {
    const result = {
      message: null,
      status: null,
    };

    const str = message.replace(/\n+/g, '');
    const regex = /status\s(\d*).*message":"(.*)"\W/gmi;

    let matches = null;
    // tslint:disable-next-line:no-conditional-assignment
    while ((matches = regex.exec(str)) !== null) {
      if (matches.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      matches.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          result.status = match;
        }
        if (groupIndex === 2) {
          result.message = match;
        }
      });
    }
    return result;
  }
}
