import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable } from 'rxjs';
import { PageModel } from 'src/app/core/models/page.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchedulingProgressService extends BaseService {
  private endpointPath = '/scheduling-progress';

  get(page: any, size: any): Observable<PageModel<any>> {
    const params = new HttpParams().append('page', page).append('size', size);
    return this.baseGetWithParams(`${this.endpointPath}`, params);
  }

}
