import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/core/services/jwt.service';
import jwt_decode from 'jwt-decode';
import { LayoutService } from 'src/app/layout/services/layout.service';

@Component({
  selector: 'app-menu-topbar',
  templateUrl: './menu-topbar.component.html',
  styleUrls: ['./menu-topbar.component.scss']
})
export class MenuTopbarComponent implements OnInit {
  perfilUsuario: any;
  constructor(
    private jwtService: JwtService,
    private router: Router,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    if (!this.jwtService.getToken()) {
      this.layoutService.off();
      this.router.navigate(['/account/login']);
    } else {
      this.layoutService.on();
      const jwt: any = jwt_decode(this.jwtService.getToken());
      this.perfilUsuario = jwt.userProfile;
    }
  }

}
