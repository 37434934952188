import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LAYOUT_CONFIG, LayoutConfig } from 'src/app/layout/configs/layout.config';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { MenuModel } from 'src/app/layout/models/menu.model';
import { D7_ROUTE_HOME } from 'src/app/core/constants/routes.constants';
import jwt_decode from 'jwt-decode';
import { JwtService } from '../../../core/services/jwt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss'],
})
export class MenuSidenavComponent implements OnInit {
  perfilUsuario: any;
  homeRoute: string;
  versionRoute: string;
  appName: string;
  appVersion: string;
  appVersionSuffix$: Observable<string>;
  menu$: Observable<MenuModel[]>;

  constructor(
    @Inject(LAYOUT_CONFIG) private layoutConfig: LayoutConfig,
    private layoutService: LayoutService,
    private jwtService: JwtService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.appName = this.layoutConfig.appName;
    this.appVersion = this.layoutConfig.appVersion;
    this.homeRoute = D7_ROUTE_HOME;
    this.loadProfile();
  }

  private loadProfile(): void {
    if (!this.jwtService.getToken()) {
      this.layoutService.off();
      this.router.navigate(['/account/login']);
    } else {
      this.layoutService.on();
      const jwt: any = jwt_decode(this.jwtService.getToken());
      this.perfilUsuario = jwt.userProfile;
    }
  }

  closeMenu(): void {
    this.layoutService.menuSidenavCollapsed();
  }
}
