<mat-toolbar class="mat-elevation-z6">
  <!--  -->
  <div fxHide.gt-sm>
    <button mat-icon-button *ngIf="!(menuSidenavOpen$ | async)" (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div *ngIf="!(menuSidenavOpen$ | async)">
    <img alt="appName" src="assets/nova-logo2.png" class="logo-d7-size" />
  </div>
  <!-- -->
  <!--<span class="title" *ngIf="(title$ | async) as title" [matTooltip]="title">{{ title }}</span>-->
  <app-menu-topbar></app-menu-topbar>
  <span fxFlex></span>

  <app-user-profile-menu></app-user-profile-menu>
</mat-toolbar>
