<section *ngIf="withExternalBar">
  <div id="barra-externa"></div>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="66" height="14">
    <defs>
      <path id="a" d="M9.367 4.374V.247H.37v4.127h8.997z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path fill="#FFF"
            d="M7.602 11.174a3.693 3.693 0 0 1-2.45.896c-.644 0-1.26-.114-1.848-.343a4.416
              4.416 0 0 1-1.54-1.001 4.863 4.863 0 0 1-1.05-1.61C.453 8.481.322 7.758.322
              6.946a4.851 4.851 0 0 1 1.47-3.521 5.092 5.092 0 0 1 1.617-1.05 5.205 5.205
              0 0 1 2.009-.385c.448 0 .877.049 1.288.147a4.495 4.495 0 0 1 2.086
              1.12c.28.266.509.567.686.903l-2.03 1.554a2.289 2.289 0 0 0-.882-.945 2.362 2.362 0 0 0-1.246-.357 2.034
              2.034 0 0 0-1.596.742c-.196.233-.35.511-.462.833a3.198 3.198 0 0 0-.168 1.057c0
              .392.056.747.168 1.064.112.317.268.59.469.819.2.229.441.406.721.532.28.126.593.189.938.189.793
              0 1.493-.327 2.1-.98H5.768v-1.96h4.088V12H7.602v-.826zm8.078.91c-.756 0-1.442-.145-2.058-.434a5.092 5.092
              0 0 1-2.604-2.758 5.008 5.008 0 0 1-.364-1.876c0-.653.126-1.283.378-1.89a5.018 5.018
              0 0 1 1.057-1.603c.453-.462.99-.83 1.61-1.106.62-.275 1.3-.413 2.037-.413.756 0
              1.442.145 2.058.434.616.29 1.143.672 1.582 1.148a5.151 5.151 0 0 1 1.015 1.624c.238.607.357
              1.227.357 1.862a4.83 4.83 0 0 1-.378 1.883 5.1 5.1 0 0 1-1.05 1.596 5.165 5.165
              0 0 1-1.603 1.113c-.62.28-1.3.42-2.037.42zm-2.254-5.04c0
              .336.047.66.14.973.093.313.233.59.42.833.187.243.422.439.707.588.285.15.623.224
              1.015.224.392 0 .733-.077 1.022-.231a2.07 2.07 0 0 0 .707-.602 2.59 2.59 0 0 0
              .406-.847c.089-.317.133-.64.133-.966 0-.336-.047-.66-.14-.973a2.397 2.397 0 0 0-.427-.826 2.156
              2.156 0 0 0-.714-.574 2.182 2.182 0 0 0-1.001-.217c-.392 0-.73.075-1.015.224-.285.15-.52.348-.707.595a2.523
              2.523 0 0 0-.413.84 3.498 3.498 0 0 0-.133.959zM23.828 2.06l1.946 6.51 1.918-6.51h2.87L26.908
              12H24.64l-3.696-9.94h2.884zM55.82 9.438c0 .383-.077.733-.231 1.05a2.444 2.444 0 0 1-.63.812 2.887 2.887
              0 0 1-.924.518c-.35.121-.726.182-1.127.182H48.19V2.06h4.942c.345 0
              .658.075.938.224.28.15.518.343.714.581.196.238.348.509.455.812.107.303.161.609.161.917
              0 .495-.124.952-.371 1.372-.247.42-.6.733-1.057.938.57.168 1.02.478
              1.351.931.331.453.497.987.497 1.603zm-1.274-.224c0-.224-.04-.441-.119-.651a1.852 1.852 0
              0 0-.322-.553 1.525 1.525 0 0 0-.483-.378 1.358 1.358 0 0
              0-.616-.14H49.45v3.416h3.458c.233 0 .448-.047.644-.14.196-.093.369-.217.518-.371a1.68
              1.68 0 0 0 .35-.539c.084-.205.126-.42.126-.644zM49.45 3.152V6.47h3.164c.224 0
              .43-.044.616-.133.187-.089.348-.208.483-.357.135-.15.243-.324.322-.525.08-.2.119-.413.119-.637a1.91 1.91 0
              0 0-.112-.658 1.736 1.736 0 0 0-.301-.532 1.37 1.37 0 0 0-1.043-.476H49.45zM57.584 12V2.06h4.256c.439 0
              .842.091 1.211.273.369.182.686.425.952.728.266.303.474.644.623 1.022.15.378.224.763.224 1.155 0
              .345-.049.679-.147 1.001a3.098 3.098 0 0 1-.42.875 2.812 2.812 0 0 1-.658.672 2.566 2.566 0 0
              1-.861.406L65.172 12h-1.428l-2.268-3.57h-2.632V12h-1.26zm1.26-4.69h3.01c.261 0
              .497-.056.707-.168.21-.112.39-.264.539-.455.15-.191.266-.413.35-.665.084-.252.126-.513.126-.784
              0-.28-.049-.544-.147-.791a2.322 2.322 0 0 0-.392-.658 1.774 1.774 0 0 0-.574-.448 1.557 1.557 0 0
              0-.693-.161h-2.926v4.13z" />
      <path fill="#FFD400" d="M35.145 0L46 6.875 35.145 13.75z" />
      <path fill="#1675D3"
            d="M36.233 11.177a4.293 4.293 0 0 0 4.3-4.3 4.293 4.293 0 0 0-4.3-4.302 4.293
              4.293 0 0 0-4.301 4.301 4.293 4.293 0 0 0 4.3 4.301" />
      <g transform="matrix(-1 0 0 1 41 4.75)">
        <mask id="b" fill="#fff">
          <use xlink:href="#a" />
        </mask>
        <path fill="#FFF" mask="url(#b)"
              d="M8.76.538C4.918-.46 2.19 1.288.533 3c-.267.248-.276.953.5
                1.375 3.554-3.313 6.797-2.9 8.035-2.185.397 0 .494-1.409-.308-1.65" />
      </g>
    </g>
  </svg>
</section>
<app-layout></app-layout>
