import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  supportList: {
    label: string;
    linkText: string;
    linkHref: string;
  }[];

  constructor() {
    this.supportList = [];
  }

  ngOnInit(): void {
    this.supportList.push({
      label: 'E-mail',
      linkHref: 'mailto: contato@d7solucoes.com.br',
      linkText: 'contato@d7solucoes.com.br',
    });
    this.supportList.push({
      label: 'Fale Conosco',
      linkHref: 'https://www.d7solucoes.com.br/contato',
      linkText: 'https://www.d7solucoes.com.br/contato',
    });
  }

}
