import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from 'src/app/core/services/jwt.service';
import jwt_decode from 'jwt-decode';
import { D7_LOGIN_ROUTE } from '../constants/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class SchedulerGuard implements CanActivate {
  constructor(
    private jwtService: JwtService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.jwtService.isToken()) {
      if (this.jwtService.tokenExpired()) {
        this.redirectLogin();
        return false;
      }
      const jwtToken: any = jwt_decode(this.jwtService.getToken());
      return (jwtToken.userProfile === 2) ? true : false;
    } else {
      /*this.jwtService.destroyToken();
      this.router.navigate([D7_LOGIN_ROUTE]);*/
      this.redirectLogin();
      return false;
    }
  }

  private redirectLogin(): void {
    console.log('SCHEDULER GUARD');
    this.jwtService.destroyToken();
    this.router.navigate([D7_LOGIN_ROUTE]);
  }
}
