import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ExtractService } from 'src/app/pages/extrato/extract.service';
import { LayoutService } from 'src/app/layout/services/layout.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.scss']
})
export class ExtratoComponent implements OnInit {
  private agendamentoId: any;
  isExtractContent = new BehaviorSubject<any>(null);
  extractContent: any;
  @ViewChild('htmlData') htmlData: ElementRef;

  constructor(
    private extractService: ExtractService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.layoutService.off();
    this.route.params.subscribe(params => {
      if (!!params.id) {
        this.agendamentoId = params.id;
        this.getSchedulerExtract();
      }
    });
  }

  openPDF(): void {
    const DATA = this.htmlData.nativeElement;
    html2canvas(DATA).then(canvas => {
      const fileWidth = 260;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 5;
      /*console.log(FILEURI);
      console.log(PDF);*/
      PDF.addImage(FILEURI, 'PNG', -23, position, fileWidth, fileHeight);

      window.open(URL.createObjectURL(PDF.output('blob')));
      // PDF.save('extrato-agendamento.pdf');
    });
  }

  getRoute(routeId: any): any {
    let routeFound;
    this.extractContent.routes.forEach(row => {
      if (row.id === routeId) {
        routeFound = row;
      }
    });
    return routeFound;
  }

  private getSchedulerExtract(): void {
    this.layoutService.loading();
    this.extractService
      .getStatement(this.agendamentoId)
      .pipe(finalize(() => {
        this.layoutService.loaded();
      }))
      .subscribe(
        (results: any) => {
          console.log(results);
          this.isExtractContent.next(results);
          this.extractContent = results;
        }
      );
  }

  getCaminhao(): any {
    const tpTruck = this.extractContent.shipping.caminhao.tipoCaminhao;
    let nomeCaminhao = '';
    if (!!this.extractContent.truckType && this.extractContent.truckType.length > 0) {
      this.extractContent.truckType.forEach((el, i) => {
        if (el.id === tpTruck) {
          nomeCaminhao = el.nome;
        }
      });
    }
    return nomeCaminhao;
  }

}
