import { Injectable } from '@angular/core';
import { LayoutModule } from '../layout.module';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogAlertConfig } from '../configs/dialog-alert.config';
import { DialogAlertComponent } from '../components/dialog-alert/dialog-alert.component';
import { __assign } from 'tslib';
import { DialogConfirmationConfig } from '../configs/dialog-confirmation.config';
import { DialogPromptConfig } from '../configs/dialog-prompt.config';
import { DialogConfirmationComponent } from '../components/dialog-confirmation/dialog-confirmation.component';
import { DialogPromptComponent } from '../components/dialog-prompt/dialog-prompt.component';
import { DialogNotificationConfig } from '../configs/dialog-notification.config';

@Injectable({
  providedIn: LayoutModule
})
export class DialogService {
  constructor(
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
  ) {}

  showAlert(configOrMessage: DialogAlertConfig | string): void {
    const config = (typeof configOrMessage === 'string') ? { message: configOrMessage } : configOrMessage;

    const configDefault = {
      message: '',
      messages: [],
      title: 'Alerta!',
      type: 'warn',
    };

    const dialog = this.bottomSheet.open(DialogAlertComponent, {
      ariaLabel: 'Diálogo com uma mensagem',
      data: __assign({}, configDefault, config)
    });

    dialog.afterDismissed().subscribe(result => {
      if (config.callback) {
        config.callback(result || false);
      }
    });
  }

  showConfirmation(config: DialogConfirmationConfig): void {
    const opts = [];
    config.anwsers.forEach((value, key) => {
      opts.push(key);
    });

    const configDefault = {
      title: 'Confirmação…',
      type: 'primary',
      options: opts,
    };

    const configToRemove = { anwsers: null };
    const dialog = this.bottomSheet.open(DialogConfirmationComponent, {
      disableClose: true,
      ariaLabel: 'Di\u00E1logo com a pergunta: ' + config.question,
      data: __assign({}, configDefault, config, configToRemove)
    });

    dialog.afterDismissed().subscribe(button => {
      if (config.anwsers.get(button)) {
        config.anwsers.get(button)();
      }
    });
  }

  showPrompt(config: DialogPromptConfig): void {
    const configDefault = {
      label: 'Informe aqui',
      button: 'Aplicar',
      title: 'Pergunta',
      required: false,
      type: 'primary',
    };

    const dialog = this.bottomSheet.open(DialogPromptComponent, {
      disableClose: !!config.required,
      ariaLabel: 'Di\u00E1logo com uma mensagem e um campo para resposta',
      data: __assign({}, configDefault, config)
    });

    dialog.afterDismissed().subscribe(result => {
      if (config.callback) {
        config.callback(result || '');
      }
    });
  }

  showNotification(configOrMessage: DialogNotificationConfig | string): void {
    setTimeout(() => {
      const config = (typeof configOrMessage === 'string') ? { message: configOrMessage } : configOrMessage;
      const secondsToClose = config.secondsToClose || 5;
      const message = config.message;
      const action = (config.action ? Object.keys(config.action)[0].toUpperCase() : '');

      const snackBarRef = this.snackBar.open(message, action, {
        duration: secondsToClose * 1000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      if (config.dismiss) {
        snackBarRef.afterDismissed().subscribe(config.dismiss);
      }
      if (config.action) {
        const actionCallback = Object.values(config.action);
        if (actionCallback.length) {
          snackBarRef.onAction().subscribe(actionCallback[0]);
        }
      }
    }, 50);
  }
}
