<h2 mat-dialog-title>{{ config.title }}</h2>
<mat-dialog-content>
  <p>{{ config.question }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <span *ngFor="let option of config.options; first as isFirst">
    <button *ngIf="isFirst" mat-raised-button [color]="config.type" (click)="closeMe(option)">
      {{ option | uppercase }}
    </button>
    <button *ngIf="!isFirst" mat-button (click)="closeMe(option)">
      {{ option | uppercase }}
    </button>
  </span>
</mat-dialog-actions>
