<h2 mat-dialog-title>{{ config.title }}</h2>
<mat-dialog-content>
  <mat-form-field class="input" appearance="outline" [color]="requiredError ? 'warn' : 'primary'">
    <mat-label>{{ config.label }}</mat-label>
    <input
        #input
        type="text"
        matInput
        [maxLength]="config.maxLength || -1"
        (keydown.enter)="closeMe(input.value)"
    />
    <button
      *ngIf="input.value"
      class="clear"
      aria-label="Limpar"
      matSuffix
      mat-icon-button
      [color]="requiredError ? 'warn' : ''"
      (click)="input.value=''"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="config?.maxLength > 0" align="end">{{ input.value?.length || 0 }} / {{ config.maxLength }}</mat-hint>
    <mat-hint *ngIf="requiredError" align="start">Campo obrigat\u00F3rio</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="closeMe(input.value)" [color]="config.type">{{ config.button | uppercase }}</button>
</mat-dialog-actions>
