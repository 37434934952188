import { Component, Inject, OnInit } from '@angular/core';
import { LAYOUT_CONFIG, LayoutConfig } from 'src/app/layout/configs/layout.config';
import { ConfigService } from 'src/app/core/services/config.service';
import { LayoutService } from 'src/app/layout/services/layout.service';

@Component({
  selector: 'app-root-container',
  templateUrl: './root-container.component.html',
  styleUrls: ['./root-container.component.scss'],
})
export class RootContainerComponent implements OnInit {
  withExternalBar: boolean;
  /**
   * inicializa o construtor
   */
  constructor(
    @Inject(LAYOUT_CONFIG) private layoutConfig: LayoutConfig,
    private configService: ConfigService,
    private layoutService: LayoutService
  ) {
  }
  /**
   *
   */
  ngOnInit(): void {
    this.layoutService.loading();
    this.configService.registerApp(this.layoutConfig.appName, this.layoutConfig.appVersion);
    this.appendMatTypographyClass();
    this.withExternalBar = this.layoutConfig.withExternalBar;
    if (this.withExternalBar) {
      this.appendExternalBarInDom();
    }
  }
  /**
   * Adiciona a barra externa no dom
   */
  appendExternalBarInDom(): void {
    try {
      const doc = (window).document;
      const head = doc.head;
      const body = doc.body;
      const meta = doc.createElement('meta');
      const script = doc.createElement('script');
      const URL_EXTERNA = 'http://estruturaorganizacional.dados.gov.br/doc/unidade-organizacional/2393';
      const URL_JS_BARRA_EXTERNA = 'https://barra.brasil.gov.br/barra_2.0.js';
      meta.setAttribute('content', URL_EXTERNA);
      meta.setAttribute('property', 'creator.productor');
      script.setAttribute('src', URL_JS_BARRA_EXTERNA);
      script.setAttribute('defer', 'defer');
      script.setAttribute('type', 'text/javascript');
      head.appendChild(meta);
      body.appendChild(script);
    } catch (e) {
      console.error('Não foi possível injetar no <head /> e no <body /> as coisas da barra externa', e);
    }
  }
  /**
   * tipografia
   */
  appendMatTypographyClass(): void {
    const doc = (window).document;
    const body = doc.body;
    body.classList.add('mat-typography');
  }

}
