import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DialogAlertConfig } from 'src/app/layout/configs/dialog-alert.config';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent {
  config: DialogAlertConfig;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DialogAlertComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.config = data;
  }

  closeMe(): void {
    this.bottomSheetRef.dismiss(true);
  }
}
