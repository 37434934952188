import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtractService extends BaseService {

  getStatement(agendamentoId: any): Observable<any> {
    return this.baseGet(`/application/requestStatement/${agendamentoId}`);
  }
}
