import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SchedulingModel } from 'src/app/scheduling/models/scheduling.model';
import { MatTableDataSource } from '@angular/material/table';
import { BalanceLoadReadModel } from '../../models/balance-load-read.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BalanceLoadConfig } from '../../configs/balance-load-config';
import { BalanceLoadService } from '../../services/balance-load.service';
import { SchedulingService } from 'src/app/scheduling/services/scheduling.service';
import { DialogService } from 'src/app/layout/services/dialog.service';
import { PageEvent } from '@angular/material/paginator';
import {
  LoadReprogracaoCargaService
} from 'src/app/scheduling/components/wizard/components/items/services/load-reprogracao-carga.service';

@Component({
  selector: 'app-balance-load-approve',
  templateUrl: './balance-load-approve.component.html',
  styleUrls: ['./balance-load-approve.component.scss']
})
export class BalanceLoadApproveComponent implements OnInit {

  formGroup: FormGroup;
  schedulingModel: SchedulingModel;
  loadingScream = false;
  loadingReprog = false;
  loadingGrid = false;
  private firstPage = 0;
  pageSize = 10;
  private id = null;
  displayedColumns: string[] = [
    'id',  'idAgendamentoItem',  'novoVolume',  'usuarioId',  'dtCadastro',  'statausReprogramacao'
  ];
  pageSizeOptions = [10, 20, 50, 100];
  length = 10;
  datasource = new MatTableDataSource<BalanceLoadReadModel>();

  @Input() showItemCarga = true;

  constructor(
    public dialogRef: MatDialogRef<BalanceLoadApproveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BalanceLoadConfig,
    private balanceLoadService: BalanceLoadService,
    private formBuilder: FormBuilder,
    private schedulingService: SchedulingService,
    private dialogService: DialogService,
    private loadReprogracaoCargaService: LoadReprogracaoCargaService,
  ) { }

  ngOnInit(): void {
    this.loadingScream = true;
    if (!!this.data.agendamento) {
      this.carregaDadosAgendamento();
      this.carregaDadosReprogramacao(this.firstPage, this.pageSize);
    }
  }

  changePage(e: PageEvent): void {
    this.carregaDadosReprogramacao(e.pageIndex, e.pageSize);
  }

  approve(id: any): void {
    const buttons: Map<string, () => void> = new Map();
    buttons.set('Não', () => console.log('Abortando ação de aprovar'));
    buttons.set('Sim', () => {
      this.loadingReprog = true;
      this.loadingGrid = true;
      this.balanceLoadService.approve(id)
        .subscribe((result) => {
          this.dialogService.showAlert({
            message: 'Alteração de carga aprovada com sucess!',
            type: 'primary'
          });
          this.loadReprogracaoCargaService.emitir(false);
          this.dialogRef.close(result);
        })
        .add(() => {});
    });

    const defaultConfig = {
      question: 'Deseja realmente APROVAR a carga?',
      title: 'Atenção!',
      anwsers: buttons,
      type: 'warn',
    };
    this.dialogService.showConfirmation(defaultConfig);
  }

  getStatus(id: any): string {
    let status = '';
    switch (id) {
      case 1:
        status = 'Pendente';
        break;
      case 2:
        status = 'Concluído';
        break;
      default:
        status = 'N/D';
    }

    return status;
  }

  private carregaDadosAgendamento(): void {
    this.schedulingService.getOne(this.data.agendamento)
      .subscribe(results => {
        this.schedulingModel = results;
      })
      .add(() => {
        this.loadingScream = false;
      });
  }

  private carregaDadosReprogramacao(page: any, size: any): void {
    this.loadingGrid = true;
    this.balanceLoadService.getDataPagination(this.data.agendamento, page, size)
      .subscribe(results => {
        this.datasource.data = results.content;
        this.length = results.totalElements;
      })
      .add(() => {
        this.loadingGrid = false;
      });
  }

}
