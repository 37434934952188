import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import jwt_decode from 'jwt-decode';
import { JwtService } from './jwt.service';
import { ConfigService } from './config.service';
import { LocalStorage } from '../utils/local.storage';
import { BaseService } from './base.service';
import { LayoutService } from 'src/app/layout/services/layout.service';

const ACCESS_TOKEN = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private currentUserSubject = new BehaviorSubject<any>(null);
  public currentUser$ = this.currentUserSubject.asObservable();

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  private httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ZDctbG9naXN0aWNhLWZyb250ZW5kLWFwcDpkNjdkMWUyYi0xM2Y4LTQzMGMtODRmMC0xMGYxYWEyMTM3MmM=',
    }),
    withCredentials: true
  };

  constructor(
    httpClient: HttpClient,
    configService: ConfigService,
    jwtService: JwtService,
    private router: Router,
    private snackBar: MatSnackBar,
    private layoutService: LayoutService,
  ) {
    super(httpClient, configService, jwtService);
  }

  /*private setAuthentication(user: any): void {
    console.log('DADOS DO USUARIO LOGADO');
    console.log(user);
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }*/

  purgeAuth(): void {
    LocalStorage.clear();
    this.currentUserSubject.next({} as any);
    this.isAuthenticatedSubject.next(false);
    this.configService.get('logoutUrl').subscribe(logoutUrl => {
      (window).location.href = logoutUrl;
    });
  }

  /*getD7LogisticaRefreshToken(paramRequest): void {
    this.configService.get('backendUrl').subscribe(backendUrl => {
      this.httpClient.post(`${backendUrl}/oauth/token`, paramRequest, this.httpOptions).subscribe(
        data => this.setupJwt(data),
        error => this.logout()
      );
    });
  }*/

  getHeaderOptions(): any {
    return this.httpOptions;
  }

  setupJwt(data: object): void {
    if (!data.hasOwnProperty(ACCESS_TOKEN)) {
      return;
    }

    this.jwtService.saveToken(data[ACCESS_TOKEN]);
    const jwtDecode: any = jwt_decode(this.jwtService.getToken());
    LocalStorage.setItem('usuario', jwtDecode);
    this.snackMessage('Conectado com sucesso', 'HTTP - 200');

    const urlRedirect = (jwtDecode.userProfile === 1) ? '/operator' : '/scheduling';
    this.router.navigate([urlRedirect]);
  }

  logout(): void {
    this.purgeAuth();
  }

  snackMessage(messageInfo: any, action: any): void {
    const config = new MatSnackBarConfig();
    config.duration = 2500;
    config.direction = 'ltr';
    config.verticalPosition = 'top';
    this.snackBar.open(messageInfo, action, config);
  }
}
