import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable } from 'rxjs';
import { PageModel } from 'src/app/core/models/page.model';
import { BalanceLoadModel } from '../models/balance-load.model';
import { HttpParams } from '@angular/common/http';
import {BalanceLoadReadModel} from '../models/balance-load-read.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceLoadService extends BaseService {
  private endpointPath = '/balance-load';

  getDataPagination(id: any, page: any, size: any): Observable<PageModel<BalanceLoadReadModel>> {
    const params = new HttpParams()
      .append('agendamentoId', id)
      .append('page', page)
      .append('size', size)
    ;
    return this.baseGetWithParams(`${this.endpointPath}/filter`, params);
  }

  salvar(body: any): Observable<BalanceLoadModel> {
    return this.basePost(`${this.endpointPath}`, body);
  }

  update(body: any, id: any): Observable<BalanceLoadModel> {
    return this.basePut(`${this.endpointPath}/${id}`, body);
  }

  drop(id: any): Observable<void> {
    return this.baseDelete(`${this.endpointPath}/${id}`);
  }

  findOne(id: any): Observable<BalanceLoadModel> {
    return this.baseGet(`${this.endpointPath}/${id}`);
  }

  existeReprogramacao(agedamentoId: any): Observable<any> {
    return this.baseGet(`${this.endpointPath}/exists/${agedamentoId}`);
  }

  approve(agedamentoId: any): Observable<any> {
    return this.baseGet(`${this.endpointPath}/approve/${agedamentoId}`);
  }
}
