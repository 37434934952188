<header fxHide.lt-lg>
  <div class="nav">

    <ul *ngIf="perfilUsuario==2">
      <li>
        <a routerLink="/scheduling" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todas os agendamentos">
            <mat-icon>list</mat-icon>
          </i>
          Solicitação
        </a>
      </li>
      <!--<li>
        <a routerLink="/scheduling/report-panel" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os usuários">
            <mat-icon>analytics</mat-icon>
          </i>
          Painel
        </a>
      </li>-->

      <li>
        <a routerLink="/scheduling-progress" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver progresso agendamento">
            <mat-icon>analytics</mat-icon>
          </i>
          Progresso
        </a>
      </li>
    </ul>

    <ul *ngIf="perfilUsuario==1">
      <li>
        <a routerLink="/operator/list_all_scheduling" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todas as solicitações">
            <mat-icon>list</mat-icon>
          </i>
          Solicitação
        </a>
      </li>
      <li>
        <a routerLink="/operator/customer_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os usuários">
            <mat-icon>groups</mat-icon>
          </i>
          Cliente
        </a>
      </li>
      <li>
        <a routerLink="/operator/order_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>local_shipping</mat-icon>
          </i>
          Pedido
        </a>
      </li>
      <li>
        <a routerLink="/operator/product_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>inventory_2</mat-icon>
          </i>
          Produtos
        </a>
      </li>
      <li>
        <a routerLink="/operator/subsidiary_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>store_mall_directory</mat-icon>
          </i>
          Filial
        </a>
      </li>
      <li>
        <a routerLink="/operator/user_list" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver todos os pedidos">
            <mat-icon>person</mat-icon>
          </i>
          Usuário
        </a>
      </li>
      <li>
        <a routerLink="/scheduling-progress" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <i mat-icon-button matTooltip="Ver progresso agendamento">
            <mat-icon>analytics</mat-icon>
          </i>
          Progresso
        </a>
      </li>
    </ul>

  </div>
</header>
