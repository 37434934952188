<mat-menu #menu="matMenu" class="mat-menu-without-padding">
  <div class="header" fxLayout="row" fxLayoutAlign="center">
    <mat-icon class="avatar" matListIcon fxFlexAlign="center">account_circle</mat-icon>
    <div class="details" fxLayout="column" fxLayoutAlign="center start">
      <p style="font-size: 18px;">{{ nomeCompleto$ | async }}</p>
      <p style="font-size: 14px;">{{ (perfil$ | async) }}</p>
      <!--<p style="font-size: 10px;">{{ (cargo$ | async) }}</p>-->
    </div>
  </div>

  <div class="list">
    <mat-divider *ngIf="helpLink"></mat-divider>
    <a mat-menu-item *ngIf="helpLink" [href]="helpLink" target="_blank">
      <mat-icon class="icon" matListIcon>help</mat-icon>
      <span>Ajuda</span>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item>
      <span>Versão: {{ version }}</span>
    </button>
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="trocarSenha()">
      <mat-icon class="icon" matListIcon>security</mat-icon>
      <span>Trocar Senha</span>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon class="icon" matListIcon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </div>
</mat-menu>

<button appOnMobile="show" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon matListIcon>account_circle</mat-icon>
</button>

<button appOnMobile="hide" class="button" mat-button [matMenuTriggerFor]="menu">
  <span class="username">{{ nomeCompleto$ | async }}</span>
  <mat-icon matListIcon>account_circle</mat-icon>
</button>
