import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { ErrorModel } from 'src/app/core/models/error.model';
import { ChangePasswordService } from 'src/app/account/services/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  submit = false;
  formPassword: FormGroup;
  notEqualPassword = '';

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private changePasswordService: ChangePasswordService,
  ) { }

  ngOnInit(): void {
    this.formPassword = this.formBuilder.group({
      currentPassword: ['', Validators.compose([Validators.required])],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }

  submitForm(): void {
    this.notEqualPassword = '';
    console.log(this.formPassword.value);
    if (!this.formPassword.valid) {
      return;
    }

    if (!this.checkEqualsPassword()) {
      this.notEqualPassword = 'A confirmação da senha não esta adequada.';
      return;
    }

    this.submit = true;
    const body = this.formPassword.value;
    this.changePasswordService
      .createRequest(body)
      .pipe(finalize(() => {
        this.submit = false;
      }))
      .subscribe(
        (results) => {
          this.dialogRef.close(results);
        },
        (error: ErrorModel) => {
          this.dialogRef.close(error);
        }
      );
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  private checkEqualsPassword(): boolean {
    return this.formPassword.get('newPassword').value.trim()
      === this.formPassword.get('confirmPassword').value.trim();
  }
}
