import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private title$ = new BehaviorSubject(null);
  private enabled$ = new BehaviorSubject(true);
  private loading$ = new BehaviorSubject(false);
  private menuSidenavOpen$ = new BehaviorSubject(null);
  private readonly onMobile$: Observable<boolean> = new BehaviorSubject(false);
  private TITLE = 'Logistica';

  constructor(
    private breakPointObserver: BreakpointObserver,
    private titleService: Title
  ) {
    this.onMobile$ = this.breakPointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(breakpointState => breakpointState.matches));
  }
  /**
   * Ajusta o título da funcionalidade
   * @param title Título da funcionalidade
   */
  public setTitle(title: string): void {
    if (title) {
      this.on();
    }
    this.titleService.setTitle(`${this.TITLE} - ${title}`);
    this.title$.next(title);
  }
  /**
   * Recupera o título da funcionidade
   */
  public getTitle(): Observable<string> {
    return this.title$.asObservable();
  }
  /**
   * Liga o layout
   */
  public on(): void {
    this.enabled$.next(true);
  }
  /**
   * Desliga o layout
   */
  public off(): void {
    this.titleService.setTitle(this.TITLE);
    this.setTitle('');
    this.enabled$.next(false);
  }
  /**
   * Recupera se o layout está ligado ou não
   */
  public isEnabled(): Observable<boolean> {
    return this.enabled$.asObservable().pipe(startWith(true), delay(0));
  }
  /**
   * Exibe o carregando...
   */
  public loading(): void {
    this.loading$.next(true);
  }
  /**
   * Esconde o carregando...
   */
  public loaded(): void {
    this.loading$.next(false);
  }
  /**
   * Recupera se está carregando ou não
   */
  public isLoading(): Observable<boolean> {
    return this.loading$.asObservable().pipe(startWith(false), delay(0));
  }
  /**
   * Recupera se está em uma tela pequena
   */
  public onMobile(): Observable<boolean> {
    return this.onMobile$;
  }
  /**
   * Recupera o estado do menu
   */
  public menuSidenavOpen(): Observable<boolean> {
    return this.menuSidenavOpen$.asObservable();
  }
  /**
   * Expande o menu
   */
  public menuSidenavExpanded(): void {
    this.menuSidenavOpen$.next(true);
  }
  /**
   * Recolhe o menu
   */
  public menuSidenavCollapsed(): void {
    this.menuSidenavOpen$.next(false);
  }
}
