import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [ MatSnackBar ]
    }
  ]
})
export class CoreModule { }

