<form [formGroup]="formPassword" (ngSubmit)="submitForm()">
  <div *ngIf="notEqualPassword" class="alert alert-danger">
    <p>{{ notEqualPassword }}</p>
  </div>
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Senha atual</mat-label>
    <input autocomplete="off" formControlName="currentPassword"
           type="password"
           matInput placeholder="Senha atual" required="true">
  </mat-form-field>
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Nova Senha</mat-label>
    <input autocomplete="off" formControlName="newPassword"
           type="password"
           matInput placeholder="Nova Senha" required="true">
    <mat-hint>Tamanho mínimo 8 caracteres</mat-hint>
  </mat-form-field>
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Confirmar nova senha</mat-label>
    <input autocomplete="off" formControlName="confirmPassword"
           type="password"
           matInput placeholder="Confirmar nova senha" type="password" required="true">
  </mat-form-field>

  <mat-progress-bar *ngIf="submit"
                    mode="indeterminate" color="primary"></mat-progress-bar>

  <div fxLayout="row">
    <div fxLayout="row" fxFlex="50" fxLayoutAlign="start center">
      <button class="action-steps-btn"
              [disabled]="submit" style="margin-left: 15px;"
              (click)="cancelar()"
              mat-raised-button color="accent">Cancelar</button>
    </div>
    <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
      <button class="action-steps-btn" style="margin-right: 15px;"
              [disabled]="submit"
              mat-raised-button color="primary">Enviar</button>
    </div>
  </div>
</form>
