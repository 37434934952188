<app-container>
  <div fxLayout="row">
    <div fxFlex="80">
      <h2>Acompanhamento de Solicitações</h2>
    </div>
    <div fxFlex="20">
    </div>
  </div>

  <div fxLayout="row" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <table mat-table [dataSource]="datasource" *ngIf="datasource" style="width: 100%" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Solicitação</th>
      <td mat-cell *matCellDef="let element">C-{{ ('0000'+element.id).slice(-4) }}</td>
    </ng-container>

    <ng-container matColumnDef="dataCriacao">
        <th mat-header-cell *matHeaderCellDef> Data Criação</th>
        <td mat-cell *matCellDef="let element;"> {{ element.dataCriacao | date: 'dd/MM/YYYY' }} </td>
    </ng-container>

    <ng-container matColumnDef="dataRetirada">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Data Retirada </th>
      <td mat-cell *matCellDef="let element;">{{ element.dataRetirada | date: 'dd/MM/YYYY' }}</td>
    </ng-container>

    <ng-container matColumnDef="caminhao">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Caminhão </th>
      <td mat-cell *matCellDef="let element"> {{ element.caminhao }} </td>
    </ng-container>

    <ng-container matColumnDef="caminhaoPlaca">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Placa </th>
      <td mat-cell *matCellDef="let element"> {{ element.caminhaoPlaca }} </td>
    </ng-container>

    <ng-container matColumnDef="motorista">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Motorista </th>
      <td mat-cell *matCellDef="let element"> {{ element.motorista }} </td>
    </ng-container>

    <ng-container matColumnDef="dtAprovado">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Aprovado </th>
      <td mat-cell *matCellDef="let element">
        <div class="text-status" *ngIf="element.dtAprovado">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && isAdminOrOperator()">
          {{ element.dtAprovado | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtChegadaCaminhao">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Chegada Caminhão </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao && element.situacao >= 9" class="text-status" >
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao && element.situacao >= 9 && isAdminOrOperator()">
          {{ element.dtChegadaCaminhao | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtAguardando">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Aguardando Carga</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao && element.dtAguardando && element.situacao >= 10" class="text-status">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao && element.dtAguardando && element.situacao >= 10 && isAdminOrOperator()">
          {{ element.dtAguardando | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtCarregando">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Carregando</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.situacao >= 11" class="text-status">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.situacao >= 11 && isAdminOrOperator()">
          {{ element.dtCarregando | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtNf">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Aguardando NF</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtNf && element.situacao >= 12" class="text-status">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtNf && element.situacao >= 12 && isAdminOrOperator()">
          {{ element.dtNf | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtCte">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Aguardando CTE </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtCte && element.situacao >= 13" class="text-status">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtCte && element.situacao >= 13 && isAdminOrOperator()">
          {{ element.dtCte | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dtLiberado">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Caminhão liberado</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtCte && element.dtNf && element.dtLiberado && element.situacao == 14" class="text-status">
          <mat-icon class="local-shipping-active">local_shipping</mat-icon>
        </div>
        <div class="text-date" *ngIf="element.dtAprovado && element.dtChegadaCaminhao  && element.dtAguardando && element.dtCarregando && element.dtCte && element.dtNf && element.dtLiberado && element.situacao == 14 && isAdminOrOperator()">
          {{ element.dtLiberado | date: 'dd/MM/YYYY HH:mm' }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="13">Nenhum registro encontrado</td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="length"
    [pageSize]="pageSize"
    (page)="changePage($event)"></mat-paginator>
</app-container>
