import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { SessionStorage } from '../utils/session.storage';
import { JwtService } from './jwt.service';


@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(
    protected httpClient: HttpClient,
    protected configService: ConfigService,
    protected jwtService: JwtService
  ) { }

  private setHeaders(multiPart = false): HttpHeaders {
    const headerConfig = {};

    if (multiPart === false) {
      headerConfig['Content-Type'] = 'application/json';
    }

    if (this.jwtService.getToken()) {
      headerConfig[`Authorization`] = `Bearer ${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headerConfig);
  }

  protected baseDelete<T>(endpoint: string): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.delete<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected baseGet<T>(endpoint: string): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.get<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected basePostDownload<T>(endpoint: string, data: any): Observable<T> {
    // @ts-ignore
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.post<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, data, {
        headers: this.setHeaders(),
        // @ts-ignore
        responseType: `blob`
      }).pipe(take(1));
    }));
  }

  protected baseGetWithParams<T>(endpoint: string, params: HttpParams | {
    [param: string]: string | string[];
  }): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.get<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        params: params,
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected baseGetCached<T>(endpoint: string): Observable<T> {
    const cacheKey = '\u00F7' + endpoint + ' @ ' + this.configService.appKey;
    if (SessionStorage.hasItem(cacheKey)) {
      return of(SessionStorage.getItem(cacheKey));
    }

    return this.baseGet<T>(endpoint).pipe(tap(data => {
      return SessionStorage.setItem(cacheKey, data);
    }));
  }

  protected baseGetText(endpoint: string): Observable<string> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.get(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        responseType: 'text',
        headers: this.setHeaders()
    }).pipe(take(1)); }));
  }

  protected baseHead<T>(endpoint: string): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.head<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected baseJsonp<T>(endpoint: string, callback: string): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.jsonp<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, callback).pipe(take(1));
    }));
  }

  protected baseOptions<T>(endpoint: string): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.options<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected basePatch<T>(endpoint: string, data: any): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.patch<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, data, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected basePost<T>(endpoint: string, data: any): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.post<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, data, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  protected basePostMultiPart<T>(endpoint: string, data: any): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.post<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, data, {
        headers: this.setHeaders(true)
      }).pipe(take(1));
    }));
  }

  protected basePut<T>(endpoint: string, data: any): Observable<T> {
    return this.configService.get('backendUrl').pipe(switchMap(backendUrl => {
      return this.httpClient.put<T>(`${backendUrl}/${this.sanitizeEndpoint(endpoint)}`, data, {
        headers: this.setHeaders()
      }).pipe(take(1));
    }));
  }

  private sanitizeEndpoint(endpoint): void {
    return endpoint.trim().replace(/^\//, '');
  }
}
