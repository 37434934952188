import { NgModule, LOCALE_ID } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

import * as packageJson from 'src/../package.json';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

@NgModule({
  imports: [
    CoreModule,
    LayoutModule.forRoot({
      withExternalBar: false,
      appName: 'Logistica',
      appVersion: packageJson.version
    }),
    NgxMaskModule.forRoot(maskConfig),
    CurrencyMaskModule,
  ],
  exports: [
    LayoutModule,
    NgxMaskModule,
    CurrencyMaskModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig }
  ],
})
export class D7Module { }
