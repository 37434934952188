<mat-card>
  <mat-card-header>
    <mat-card-title>
      <code>:(</code>
      Ops!
    </mat-card-title>
    <mat-card-subtitle>Página não encontrada</mat-card-subtitle>
    <button mat-fab color="accent" routerLink="/">
      <mat-icon>home</mat-icon>
    </button>
  </mat-card-header>
  <img mat-card-image alt="Imagem de Pagina não encontrada" src="/assets/404-page-not-found-en.png" />
  <mat-card-content>
    <p>
      Tente digitar outra URL na barra de endereço, ou acesse a <a style="cursor: pointer;" routerLink="/">página inicial</a> do sistema.
    </p>
  </mat-card-content>
</mat-card>
