<app-container>

  <section *ngIf="isExtractContent | async">

    <section #htmlData>
      <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 5px;">
        <button (click)="openPDF()"
                mat-raised-button
                color="primary"
        >Gerar PDF</button>
      </div>

      <div fxLayout="row">
        <div fxFlex="15"></div>
        <div fxFlex="70">

          <div fxLayout="row">
            <div fxFlex="35">
              <img src="assets/nova-logo2.png" width="210" height="131" alt="CAMPEÃ AGRONEGÓCIOS S.A">
            </div>
            <div fxFlex="65">
              <h1 style="letter-spacing: 1px; font-size: xx-large">CAMPEÃ AGRONEGÓCIOS S.A</h1>
            </div>
          </div>

          <div fxLayout="row" style="background-color: #dad7d7; border: 1px solid#000000; margin-top: 10px;">
            <h1 style="color: black; margin-top: 15px; margin-left: 10px;">
              Informações da Solicitação</h1>
          </div>

          <div fxLayout="row" style="font-size: 18px; margin-top: 15px;">
            <div fxFlex="25"><span style="font-weight: bold;">Ordem : C-{{ ('0000'+extractContent.retrieve.id).slice(-4) }}</span></div>

            <div fxFlex="50"><span style="font-weight: bold;">Criada em</span>: {{ extractContent.retrieve.dtCriacao | date: 'dd/MM/yyyy' }}</div>

            <div fxFlex="35"><span style="font-weight: bold;">Status</span>: {{ extractContent.retrieve.noEstado | uppercase }}</div>
          </div>

          <div fxLayout="row" style="font-size: 18px; margin-top: 10px;">
            <div fxFlex="25"><span style="font-weight: bold;">Data Retirada</span>:{{ extractContent.retrieve.dtRetirada | date: 'dd/MM/yyyy' }}</div>

            <div fxFlex="50"><span style="font-weight: bold;">Filial</span>: {{ extractContent.retrieve.filial | uppercase }}</div>

            <div fxFlex="35"><span style="font-weight: bold;">Local</span>: {{ extractContent.retrieve.endereco | uppercase }}</div>
          </div>

          <div fxLayout="row" style="font-size: 18px; margin-top: 10px;">
            <div fxFlex="50"><span style="font-weight: bold;">Solicitante</span>:</div>
            <div fxFlex="30"><span style="font-weight: bold;">E-mail</span>:</div>
            <div fxFlex="20"><span style="font-weight: bold;">Contato</span>:</div>
          </div>
          <div fxLayout="row" style="font-size: 18px; margin-top: 10px;">
            <div fxFlex="50">{{ extractContent.requesterDTO.nome | uppercase }}</div>
            <div fxFlex="30">{{ extractContent.requesterDTO.email | lowercase }}</div>
            <div fxFlex="20">{{ extractContent.requesterDTO.ddd }} {{ extractContent.requesterDTO.telefone | mask: '00000-0000' }}</div>
          </div>

          <!--<div fxLayout="row" style="font-size: 20px; margin-top: 20px;">
            <div fxFlex="50"><span style="font-weight: bold;">Peso Aproximado (KG)</span>: 30.000</div>
            <div fxFlex="50"></div>
          </div>-->

          <div fxLayout="row" style="font-size: 20px; margin-top: 20px;">
            <div fxFlex="60">
              <span style="font-weight: bold;">Destinatário</span>: {{ extractContent.retrieve.cliente | uppercase }}
              - {{ extractContent.retrieve.cnpj | mask: '00.000.000/0000-00' }}
            </div>
            <div fxFlex="40">
              <span style="font-weight: bold;">Telefone(s)</span>: {{ extractContent.retrieve.ddd }} {{ extractContent.retrieve.telefone | mask: '00000-0000' }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="20">
              <div fxLayout="column" STYLE="font-weight: bold; font-size: 16px">
                <div STYLE="margin-top: 20px;"></div>
                <div STYLE="margin-top: 20px;">Motorista</div>
                <div STYLE="margin-top: 20px;">Finalidade</div>
                <div STYLE="margin-top: 20px;"></div>
              </div>
            </div>
            <div fxFlex="30" STYLE="font-size: 20px">
              <div fxLayout="column">
                <div STYLE="margin-top: 20px;"></div>
                <div STYLE="margin-top: 20px;">{{ extractContent.shipping.motorista.nome | uppercase }}</div>
                <div STYLE="margin-top: 20px;">CULTIVAR</div>
                <div STYLE="margin-top: 20px;"></div>
              </div>
            </div>
            <div fxFlex="50" fxLayoutAlign="end center">
              <div style="margin-top: 60px">Assinatura do liberador:_______________________________________________________</div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="50">
              <div fxLayout="column">
                <div STYLE="margin-top: 20px; font-weight: bold; font-size: 16px;">SEQUENCIA DE DESCARGA</div>
                <div fxLayout="row" *ngFor="let r of extractContent.routes">
                  <div fxFlex="10">{{ r.ordemDescarregamento }}</div>
                  <div fxFlex="45">{{ r.tagEndereco | uppercase }}</div>
                  <div fxFlex="45">{{ r.tagCliente | uppercase }}</div>
                </div>
              </div>
            </div>
            <div fxFlex="50">
              <div fxLayout="column">
                <div STYLE="margin-top: 20px; font-weight: bold; font-size: 16px;">CONTATOS</div>
                <div fxLayout="row" *ngFor="let c of extractContent.contatos">
                  <div fxFlex="30">{{ c.nome | uppercase }}</div>
                  <div fxFlex="40">{{ c.email | lowercase }}</div>
                  <div fxFlex="20" *ngIf="!c.ddd && c.telefone">{{ c.ddd }} {{ c.telefone | mask: '00000-0000' }}</div>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" style="margin-top: 70px;">
            <table border="1" class="mat-table" style="width: 100%;">
              <thead>
              <tr>
                <td style="padding: 10px;font-size: 22px;">Item</td>
                <td style="padding: 10px;font-size: 22px;">Quantidade</td>
                <td style="padding: 10px;font-size: 22px;">Embalagem</td>
                <td style="padding: 10px;font-size: 22px;">Cultivar</td>
                <td style="padding: 10px;font-size: 22px;">Nº Pedido</td>
                <td style="padding: 10px;font-size: 22px;">Tipo de TSI</td>
                <td style="padding: 10px;font-size: 22px;">Sequencia<br>Descarga</td>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of extractContent.itemsAgendados; let i = index;">
                  <td style="padding: 10px; height: 3px;">{{ i+1 }}</td>
                  <td style="padding: 10px;">{{ item.volume }}</td>
                  <td style="padding: 10px;">{{ item.tipoEmbalagem }}</td>
                  <td style="padding: 10px;">{{ item.nomeComercial }}</td>
                  <td style="padding: 10px;">{{ item.numeroPedido }}</td>
                  <td style="padding: 10px;">{{ item.tsi }}</td>
                  <td style="padding: 10px;">{{ getRoute(item.rotaId).ordemDescarregamento }}</td>
                </tr>
                <tr style="background-color: #f5f6ff;">
                  <td style="padding: 10px; height: 60px;"></td>
                  <td colspan="2" style="padding: 10px; height: 60px;">
                    LOCAL DE CARREGAMENTO:
                  </td>
                  <td colspan="4" style="padding: 10px; height: 60px;"></td>
                </tr>
                <tr>
                  <td rowspan="9" style="padding: 10px;">
                    <p style="width:1px;word-wrap: break-word;font-family: monospace; margin-left: 25px; font-weight: bold">
                      Transportadora
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;">Transportadora (manifestar a carga)</td>
                  <td style="padding: 10px; height: 50px;">CNPJ/CPF</td>
                  <td style="padding: 10px;" colspan="2">Celular</td>
                  <td style="padding: 10px;">CEP</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;background-color: #fcf8e3;">
                    {{ extractContent.shipping.transportadora.nomeFantasia }}<br>
                    {{ extractContent.shipping.transportadora.razaoSocial }}
                  </td>
                  <td style="padding: 10px;background-color: #fcf8e3; height: 35px;">{{ extractContent.shipping.transportadora.cnpj | mask: '00.000.000/0000-00' }}</td>
                  <td style="padding: 10px;background-color: #fcf8e3;" colspan="2">
                    {{ extractContent.shipping.transportadora.ddd }} - {{ extractContent.shipping.transportadora.telefone | mask: '00000-0000' }}
                  </td>
                  <td style="padding: 10px;background-color: #fcf8e3;"></td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;">Endereço</td>
                  <td style="padding: 10px; height: 50px;">Insc. Est.</td>
                  <td style="padding: 10px;">Telefone fixo</td>
                  <td colspan="2" style="padding: 10px;">Email</td>
                  <!--<td style="padding: 10px;"></td>-->
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;background-color: #fcf8e3;"></td>
                  <td style="padding: 10px;background-color: #fcf8e3; height: 35px;">{{ extractContent.shipping.transportadora.inscricaoEstadual }}</td>
                  <td style="padding: 10px;background-color: #fcf8e3;">{{ extractContent.shipping.transportadora.ddd }} {{ extractContent.shipping.transportadora.telefone | mask: '00000-0000' }}</td>
                  <td style="padding: 10px;background-color: #fcf8e3;" colspan="2">{{ extractContent.shipping.transportadora.email | lowercase }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;">MOTORISTA</td>
                  <td style="padding: 10px; height: 50px;" colspan="2">CPF</td>
                  <!--<td style="padding: 10px;">PLACA CAMINHÃO</td>-->
                  <td style="padding: 10px;" colspan="2">Telefone</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 10px;background-color: #fcf8e3;">{{ extractContent.shipping.motorista.nome | uppercase }}</td>
                  <td style="padding: 10px; height: 35px;background-color: #fcf8e3;" colspan="2">{{ extractContent.shipping.motorista.cpf | mask: '000.000.000-00' }}</td>
                  <!--<td style="padding: 10px;background-color: #fcf8e3;">{{ extractContent.shipping.caminhao.placa | uppercase }}</td>-->
                  <td style="padding: 10px;background-color: #fcf8e3;" colspan="2">{{ extractContent.shipping.motorista.ddd }} {{ extractContent.shipping.motorista.telefone | mask: '00000-0000' }}</td>
                </tr>

                <tr>
                  <td colspan="3" style="padding: 10px;">CAMINHÃO</td>
                  <td style="padding: 10px; height: 50px;" colspan="3">PLACA CAMINHÃO</td>
                </tr>
                <tr>
                  <td colspan="3" style="padding: 10px;background-color: #fcf8e3;">{{ getCaminhao() }}</td>
                  <td style="padding: 10px; height: 35px;background-color: #fcf8e3;" colspan="3">{{ extractContent.shipping.caminhao.placa | uppercase }}</td>
                </tr>

              </tbody>
            </table>
          </div>

          <div fxLayout="row" style="margin-top: 30px;">
            <div fxFlex="50" fxLayoutAlign="center center" style="border-bottom: 1px solid #000000;"></div>
            &nbsp;&nbsp;&nbsp;
            <div fxFlex="50" fxLayoutAlign="center center" style="border-bottom: 1px solid #000000;"></div>
          </div>
          <div fxLayout="row" style="font-weight: bold;">
            <div fxFlex="50" fxLayoutAlign="center center">
              MOTORISTA
            </div>
            <div fxFlex="50" fxLayoutAlign="center center">
              RESPONSÁVEL PELO CARREGAMENTO
            </div>
          </div>

        </div>
        <div fxFlex="15"></div>
      </div>
    </section>

  </section>
</app-container>
