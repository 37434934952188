import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorage } from 'src/app/core/utils/local.storage';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/account/components/change-password/change-password.component';
import { ErrorModel } from 'src/app/core/models/error.model';
import * as packageJson from 'src/../package.json';

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit {
  helpLink: string;
  nomeCompleto$: Observable<string>;
  perfil$: Observable<string>;
  version: string;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const u = LocalStorage.getItem('usuario');
    if (!!u) {
      this.nomeCompleto$ = of(u.userName);
      this.perfil$ = of(u.userPerfil);
    }
    this.version = packageJson.version;
  }

  logout(): void {
    this.authService.logout();
  }

  trocarSenha(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '600px',
      height: '550px',
      data: {},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result instanceof ErrorModel) {
        return;
      }
      // tslint:disable-next-line:no-unused-expression
      !!result && alert('Senha alterada com sucesso');
    });
  }
}
